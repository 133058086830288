<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('germplasmReport.crop_info_excel') }}</h4>
        </template>
       <template v-slot:body>
          <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset">
             <b-row>
                <b-col xl="6" lg="6" md="6" sm="12" xs="12">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('globalTrans.year')"
                    label-for="collection_date"
                  >
                    <b-form-input
                      class="fromDate"
                        v-model="search.collection_date"
                      :placeholder="$t('globalTrans.year')"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                  <ValidationProvider name="Crop Type" vid="crop_type_id">
                    <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="crop_type_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{ $t('germplasmReport.crop_type') }}
                      </template>
                      <b-form-select
                        plain
                        v-model="search.crop_type_id"
                        :options="CropTypeList"
                        id="crop_type_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="12">
                  <ValidationProvider name="Accession Number" vid="accession_number">
                    <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="accession_number"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{ $t('germplasmReport.accession_number') }}
                      </template>
                      <b-input
                        plain
                        v-model="search.accession_number"
                        id="accession_number"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                      </b-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="12">
                  <ValidationProvider name="Organization" vid="org_id">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="org_id"
                    >
                    <template v-slot:label>
                      {{$t('org_pro.organization')}}
                    </template>
                    <b-form-select
                      plain
                      v-model="search.org_id"
                      :options="orgList"
                      id="org_id"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6">
                  <b-button type="submit" variant="primary">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
      </template>
    </iq-card>
        <b-row v-show="showData">
        <b-col md="12">
          <iq-card>
            <template v-slot:body>
              <b-overlay :show="loadingState">
                  <b-row v-show="showData">
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{  $t('germplasmReport.germplasm-production-report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                              <export-excel
                                class="btn btn-success mr-2"
                                :data="dataCustomizeExcel"
                                :fields="json_fields"
                                worksheet="Germplasn Production Report"
                                name="filename.xls">
                                {{ $t('globalTrans.export_excel') }}
                              </export-excel>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row>
                              <b-col>
                                <list-report-head :base-url="seedFertilizerServiceBaseUrl" :uri="reportHeadingList" :org-id= "3">
                                  {{ $t('germplasmReport.germplasm-production')}}
                                </list-report-head>
                              </b-col>
                              </b-row>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                 <b-table thead-class="table_head" bordered hover :items="cropInfo" :fields="columns" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                  <template v-slot:cell(index)="data">
                                    {{ $n(data.index + pagination.slOffset) }}
                                  </template>
                                   <template v-slot:cell(accession_number)="data">
                                    {{ data.item.accession_number }}
                                  </template>
                                  <template v-slot:cell(quantity)="data">
                                    {{ $n(data.item.quantity, { useGrouping: false }) }}
                                  </template>
                                  <template v-slot:cell(status)="data">
                                    {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                                  </template>
                                </b-table>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, germplasmCropExcel } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import excel from 'vue-excel-export'
import Vue from 'vue'
import flatpickr from 'flatpickr'
Vue.use(excel)

const excelColumn = {
   Year: 'year',
  'Country ISO3 code': 'countryCode',
  'Country name': 'countryName',
  'Holding institute code': 'holdingCode',
  'Holding institute name': 'holdingName',
  'Accession number': 'accessionNumber',
   Taxon: 'taxon',
   Genus: 'genus',
   Species: 'species',
  'Crop name': 'cropName',
  'Acquisition date (YYYY/MM)': 'acquisitionDate',
  'Country of origin (ISO3)': 'countryOfOriginISO3',
  'Country of origin': 'countryOfOrigin',
  'Biological status': 'biologicalStatus',
  'Genebank(s) holding safety duplications - code': 'genebankcode',
  'Genebank(s) holding safety duplications': 'genebank',
  'Latitude of collecting site (decimal degrees format)': 'latitudeOfSite',
  'Longitude of collecting site (decimal degrees format)': 'longitudeOfSite',
  'Collecting/acquisition source': 'collectingSource',
  'Type of germplasm storage': 'germplasmStorage',
  'Status under the Multilateral System': 'multilateralSystem',
  'Data owner': 'dataOwner',
  'Data owner details': 'OwnerDetails',
  'Source of information': 'sourceOfInfo'
}

export default {
  name: 'UiDataTable',
  components: {
    ListReportHead
  },
  data () {
    return {
      showData: false,
      showHeading: false,
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        collection_date: '',
        to_date: '',
        accession_number: '',
        crop_type_id: 0,
        org_id: this.$store.state.dataFilters.orgId

      },
      cropInfo: [],
      excelData: [],
      testId: 0,
      rows: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList,
      loading: false
    }
  },
  computed: {
      CropTypeList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
      },
      json_fields: function () {
        return excelColumn
      },
      dataCustomizeExcel () {
        const customizeData = this.cropInfo.map(item => {
            var d = new Date(item.collection_date)
            var taxon = item.scientific_name.split(' ')
            item.year = d.getFullYear()
            item.countryCode = 'BGD'
            item.countryName = 'Bangladesh'
            item.holdingCode = item.crop
            item.holdingName = item.crop
            item.accessionNumber = item.accession_number
            item.taxon = item.scientific_name
            item.genus = taxon[0] === 'undefined' ? '' : taxon[0]
            item.species = taxon[1] === 'undefined' ? '' : taxon[1]
            item.cropName = item.crop_name
            item.acquisitionDate = item.collection_date
            item.countryOfOriginISO3 = 'BGD'
            item.countryOfOrigin = 'Bangladesh'
            item.biologicalStatus = item.crop
            item.genebankcode = item.crop
            item.genebank = item.crop
            item.latitudeOfSite = item.latitude
            item.longitudeOfSite = item.longitude
            item.collectingSource = item.crop
            item.germplasmStorage = item.storage_type
            item.multilateralSystem = item.crop
            item.dataOwner = item.crop
            item.OwnerDetails = item.crop
            item.sourceOfInfo = item.crop
          return Object.assign({}, item)
        })
        return customizeData
      },
      listReload () {
        return this.$store.state.commonObj.listReload
      },
      loadingState () {
        if (this.listReload) {
          return true
        } else if (this.loading) {
          return true
        } else {
          return false
        }
      },
      columns () {
        const labels = [
            { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
            { label: this.$t('germplasmReport.accession_number'), class: 'text-left' },
            { label: this.$t('germConfig.cropName'), class: 'text-left' },
            { label: this.$t('germConfig.scientific_name'), class: 'text-left' },
            { label: this.$t('germConfig.english_name'), class: 'text-left' }
          ]
        let keys = []

        if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'index' },
            { key: 'accession_number' },
            { key: 'crop_name_bn' },
            { key: 'scientific_name_bn' },
            { key: 'english_name_bn' }
          ]
        } else {
          keys = [
            { key: 'index' },
            { key: 'accession_number' },
            { key: 'crop_name' },
            { key: 'scientific_name' },
            { key: 'english_name' }
          ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
      },
      orgList: function () {
       return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      }
  },
  watch: {},
  created () {
    this.loadData()
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  methods: {
    searchClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length
      }
    },
    searchData () {
      this.loadData()
      this.showData = true
      this.showHeading = true
    },
   loadData () {
      this.loading = true
      RestApi.getData(seedFertilizerServiceBaseUrl, germplasmCropExcel, this.search).then(response => {
        if (response.success) {
          this.cropInfo = this.formatData(response.data)
        }
        this.loading = false
      }).catch(error => {
        if (error) {
          //
        }
        this.cropInfo = []
      })
    },
    formatData (data) {
      const formattedData = data.map(item => {
        const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList.find(cropName => cropName.value === item.crop_name_id && cropName.status === 1)
        const typeOfGermplasmStorage = this.$store.state.SeedsFertilizer.commonObj.gpConservationTypeList.find(list => list.value === item.conservation_type_id)
        const tmpBindingData = {
          crop_name: cropNameList !== undefined ? cropNameList.text_en : '',
          crop_name_bn: cropNameList !== undefined ? cropNameList.text_bn : '',
          english_name: cropNameList !== undefined ? cropNameList.english_name : '',
          english_name_bn: cropNameList !== undefined ? cropNameList.english_name_bn : '',
          scientific_name: cropNameList !== undefined ? cropNameList.scientific_name : '',
          scientific_name_bn: cropNameList !== undefined ? cropNameList.scientific_name_bn : '',
          storage_type: typeOfGermplasmStorage !== undefined ? typeOfGermplasmStorage.text_en : typeOfGermplasmStorage.text_bn
        }
        return Object.assign({}, item, tmpBindingData)
      })
      return formattedData
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
